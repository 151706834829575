export { Add } from './Add';
export { AlertFill } from './AlertFill';
export { ArrowLeft } from './ArrowLeft';
export { ArrowLeftLine } from './ArrowLeftLine';
export { ArrowRight } from './ArrowRight';
export { ArrowRightCircleLine } from './ArrowRightCircleLine';
export { Bizum } from './Bizum';
export { BizumSmall } from './BizumSmall';
export { Box } from './Box';
export { Camion } from './Camion';
export { Cart } from './Cart';
export { Chat } from './Chat';
export { CheckOk } from './checkOk';
export { CheckboxCircle } from './CheckboxCircle';
export { ChevronDown } from './ChevronDown';
export { ChevronLeft } from './ChevronLeft';
export { ChevronRight } from './ChevronRight';
export { Close } from './Close';
export { CloseIcon } from './CloseIcon';
export { Compass } from './Compass';
export { Copy } from './Copy';
export { Edit } from './Edit';
export { EstrellaGalicia } from './EstrellaGalicia';
export { Facebook } from './Facebook';
export { Filter } from './Filter';
export { GestionConsentimiento } from './gestionConsentimiento';
export { GestionSubs } from './gestionSubs';
export { Gift } from './Gift';
export { Heart } from './Heart';
export { HeartFill } from './HeartFill';
export { Info } from './Info';
export { Instagram } from './Instagram';
export { Less } from './Less';
export { LinkedIn } from './LinkedIn';
export { ListCheck } from './ListCheck';
export { Mastercard } from './Mastercard';
export { Menu } from './Menu';
export { Mic } from './Mic';
export { OjoCerrado } from './OjoCerrado';
export { OrderWithIncidence } from './OrderWithIncidence';
export { PaperTextureLeft } from './PaperTextureLeft';
export { PaperTextureRight } from './PaperTextureRight';
export { PayPal } from './PayPal';
export { PayPalFull } from './PaypalFull';
export { PayPalLogo } from './PayPalLogo';
export { Phone } from './Phone';
export { Plant } from './Plant';
export { Play } from './Play';
export { Reload } from './Reload';
export { Reloj } from './Reloj';
export { Search } from './Search';
export { Send } from './Send';
export { Settings } from './Settings';
export { Share } from './Share';
export { SocialFacebook } from './SocialFacebook';
export { SocialGoogle } from './SocialGoogle';
export { SocialTwitter } from './SocialTwitter';
export { Spinner } from './Spinner';
export { Stop } from './Stop';
export { SymbolCart } from './SymbolCart';
export { Ticket } from './Ticket';
export { TikTok } from './TikTok';
export { Trash } from './Trash';
export { Twitter } from './Twitter';
export { Upload } from './Upload';
export { User } from './User';
export { UserActive } from './UserActive';
export { Visa } from './Visa';
export { YouTube } from './YouTube';
export { GooglePay } from './GooglePay';
export { ApplePay } from './ApplePay';
export { WhatsApp } from './WhatsApp';

export type { IconType } from './types';
