import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Upload
 */
export const Upload = ({
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
  ...props
}: IconType): JSX.Element => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill={color}
      d="M3 19h18v2H3v-2ZM13 5.828V17h-2V5.828L4.929 11.9l-1.414-1.414L12 2l8.485 8.485-1.414 1.414L13 5.83v-.002Z"
    />
  </svg>
);
